// Add the following imports to your React code
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useLocation } from 'react-router-dom';


// Modify your component to fetch data based on the key parameter
const QrDetails = () => {
    const { key } = useParams();
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    //const key = searchParams.get('uid');
    const [qrData, setQrData] = useState(null);
    const [isCompleted, setIsCompleted] = useState(false);

    useEffect(() => {
        // Fetch QR data based on the key parameter
        console.log(key)
        fetch(`https://arshiya-flask-backend-test.onrender.com/qr/${key}`)
            .then(response => {
                if (!response.ok) {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }
                return response.json();
            })
            .then(data => {
                setQrData(data);
                setIsCompleted(true)
            })
            .catch(error => {
                console.error('Error fetching QR data:', error);
                setIsCompleted(true);
                // Handle the error, e.g., set an error state or redirect the user
            });
    }, [key]);

    return (
        <div className="container mx-auto mt-8">
            {isCompleted ? (
                qrData ? (
                    <div>
                        <h1 className="text-3xl font-bold text-green-500 mb-4">Authenticate Product</h1>
                        <div className="border p-4 mb-4">
                            <h2 className="text-xl font-bold mb-2">QR Data Details</h2>
                            <p className="mb-4">Batch No: {qrData.contains}</p>
                            <p className="mb-4">Serial No: {qrData.serial_no}</p>
                            <h3 className="text-lg font-bold mb-2">Company Details</h3>
                            <p>Company Name: {qrData.companyDetails.companyName}</p>
                            <p>Brand Name: {qrData.companyDetails.brandName}</p>

                            <p>Max Retail Price: {qrData.companyDetails.mrp}</p>
                            <p>Phone No: {qrData.companyDetails.phoneNo}</p>
                            <p>GST No: {qrData.companyDetails.gst}</p>
                            <p>Proprietor Name: {qrData.companyDetails.propName}</p>
                            <p>Address: {qrData.companyDetails.address}</p>
                            <p>RC No: {qrData.companyDetails.rcNo}</p>
                            <p>TradeMark No: {qrData.companyDetails.tmNo}</p>
                            <div className="mt-4">
                                {/* Displaying logo image */}
                                {qrData.companyDetails.logo_data && (
                                    <img
                                        src={`data:image/png;base64,${qrData.companyDetails.logo_data}`}
                                        alt="Logo"
                                        className="max-w-full h-auto"
                                        style={{ maxWidth: '300px' }}
                                    />
                                )}
                            </div>
                            <div className="mt-4">
                                {/* Displaying label image */}
                                {qrData.companyDetails.labelImage_data && (
                                    <img
                                        src={`data:image/png;base64,${qrData.companyDetails.labelImage_data}`}
                                        alt="Label"
                                        className="max-w-full h-auto"
                                        style={{ maxWidth: '300px' }}
                                    />
                                )}
                            </div>
                        </div>
                    </div>
                ) : (
                    <div>
                        <h1 className="text-3xl font-bold text-red-500 mb-4">Product Can Not be Verified</h1>
                        <p className="text-lg">
                            The product you are looking to search may be a fake product.
                        </p>
                    </div>
                )
            ) : null}
        </div>
    );



};

export default QrDetails;
